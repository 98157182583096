/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TagType } from "./tag";

export interface ThemeColorsType {
    /**
     * Clock, events, etc
     */
    foreground: string;
    /**
     * Text laid on top of foreground (e.g. date on mobile)
     */
    accent: string;
}

export interface ThemeBackgroundPositioningConfigType {
    offsetFromCenter: {
        /** % (0-100) */
        y: number;
        /** % (0-100) */
        x: number;
    }
}

export enum AttributionKindEnum {
    ROYALTY_FREE = 'royalty-free',
    LICENSED = 'licensed',
    CREDIT_REQUIRED = 'credit-required',
}

export interface ThemeAttributionType {
    creator: string;
    attributionKind: AttributionKindEnum;
}

export interface ThemeBackgroundBaseType {
    attribution: ThemeAttributionType;
    positioning: ThemeBackgroundPositioningConfigType;
}

export interface ThemeBackgroundImageType extends ThemeBackgroundBaseType {
    type: 'image';
    src: string;
}

export interface ThemeBackgroundVideoType extends ThemeBackgroundBaseType {
    type: 'video';
    srcs: Array<{
        src: string;
        mimeType: string;
    }>;
}

export interface ThemeType {
    uuid: string;
    
    label: string;
    description: string;
    tags: TagType[];
    
    colors: ThemeColorsType;
    background: ThemeBackgroundImageType | ThemeBackgroundVideoType;
}

/// quotes dont forget quotes