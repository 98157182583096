/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
import { SeamlessClient, SeamlessClientAuthExtension } from '@seamlessjs/react';

export const seamlessClient = new SeamlessClient({ socketUrl: window.location.host === 'localhost:3000' ? 'http://localhost:3001' : '/' });
export const seamlessAuth = new SeamlessClientAuthExtension<{PublicAuthType: {userId: string, type: 'hiyllo-employee' | 'customer'}, AuthRequestType: {token: string}}>(seamlessClient, {
  autoLoginFunction: async () => {
    const token = window.sessionStorage.token;

    if (token != null) {
      return { token };
    }

    return null;
  }
});
