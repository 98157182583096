import React from 'react';

import { Input } from '@hiyllo/ux/input';
import { Select } from '@hiyllo/ux/select';
import { Button } from '@hiyllo/ux/button';

import * as CreateThemeBP from '../../blueprints/admin/create-theme';

import {
  AttributionKindEnum,
  ThemeAttributionType,
  ThemeBackgroundImageType, ThemeBackgroundVideoType, ThemeType
} from '../../types/theme';
import { seamlessClient } from '../../seamless';
import { UseSeamlessQueryRetValAny } from '@seamlessjs/react/main';

export async function getBackground (type: 'video' | 'image', srcs: string, creator: string, attributionKind: AttributionKindEnum): Promise<ThemeBackgroundImageType | ThemeBackgroundVideoType> {
  const attribution: ThemeAttributionType = {
    creator,
    attributionKind
  };

  const positioning = {
    offsetFromCenter: {
      y: 0,
      x: 0
    }
  };

  if (type === 'image') {
    return {
      positioning,
      attribution,
      type: 'image',
      src: srcs
    };
  } else {
    return {
      positioning,
      attribution,
      type: 'video',
      srcs: await Promise.all(srcs.split(',').map(async src => {
        const req = await fetch(src, { method: 'GET' });

        return {
          src,
          mimeType: req.headers.get('content-type') as string
        };
      }))
    };
  }
}

export const CreateTheme = React.memo(function CreateTheme (props: {
  listThemesQuery: UseSeamlessQueryRetValAny
  onDone: () => void
}): JSX.Element {
  const createThemeMutation = seamlessClient.useSeamlessMutation<CreateThemeBP.Plug>(CreateThemeBP, { querySideEffects: [props.listThemesQuery] });
  const [label, setLabel] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [foreground, setForeground] = React.useState('');
  const [accent, setAccent] = React.useState('');
  const [backgroundType, setBackgroundType] = React.useState<'video' | 'image'>('image');
  const [srcs, setSrcs] = React.useState('');
  const [creator, setCreator] = React.useState('');
  const [attributionKind, setAttributionKind] = React.useState<AttributionKindEnum>(AttributionKindEnum.CREDIT_REQUIRED);

  const onSubmit = React.useCallback(async () => {
    const theme: Omit<ThemeType, 'uuid'> = {
      label,
      description,
      tags: [],
      colors: {
        foreground,
        accent
      },
      background: await getBackground(backgroundType, srcs, creator, attributionKind)
    };

    void createThemeMutation.call({ theme }).then(props.onDone);
  }, [label, description, foreground, accent, backgroundType, srcs, creator, attributionKind]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 16
    }}>
      <Input
        label='Label'
        onChangeValue={setLabel}
        value={label}
      />

      <Input
        label='Description'
        onChangeValue={setDescription}
        value={description}
        multiline
      />

      <Input
        label='Foreground'
        placeholder='Clock, Events, Etc'
        onChangeValue={setForeground}
        value={foreground}
      />

      <Input
        label='Accent'
        placeholder='e.g. date text on mobile'
        onChangeValue={setAccent}
        value={accent}
      />

      <Input
        label='Creator'
        placeholder='Attribution for the creator'
        onChangeValue={setCreator}
        value={creator}
      />

      <div>
        <div style={{ paddingBottom: 6 }}>Attribution Kind</div>
        <Select
          value={attributionKind}
          onChangeValue={v => setAttributionKind(v as AttributionKindEnum)}
          options={[
            {
              label: 'Royalty Free',
              value: AttributionKindEnum.ROYALTY_FREE
            },
            {
              label: 'Licensed',
              value: AttributionKindEnum.LICENSED
            },
            {
              label: 'Credit Required',
              value: AttributionKindEnum.CREDIT_REQUIRED
            }
          ]}
        />
      </div>

      <div>
        <div style={{ paddingBottom: 6 }}>Background Type</div>
        <Select
          value={backgroundType}
          onChangeValue={v => setBackgroundType(v as 'video' | 'image')}
          options={[
            {
              label: 'Video',
              value: 'video'
            },
            {
              label: 'Image',
              value: 'image'
            }
          ]}
        />
      </div>

      <Input
        label={backgroundType === 'video' ? 'Video SRCs (Separate with commas)' : 'Image SRC'}
        placeholder='https://cdn.hiyllo.net/videos/my-video.mp4'
        onChangeValue={setSrcs}
        value={srcs}
        fullWidth
      />

      <Button
        label="Create"
        onClick={() => {
          void onSubmit();
        }}
        isLoading={createThemeMutation.isLoading}
      />
    </div>
  );
});
