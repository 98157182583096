import React from 'react';

import * as ListThemesBP from '../../blueprints/themes/list-themes';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { seamlessAuth, seamlessClient } from '../../seamless';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { CreateTheme } from './create-theme';
import {
  ThemeBackgroundImageType, ThemeBackgroundVideoType, ThemeType
} from '../../types/theme';

const OuterContainer = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  height: '100%'
}));

const InnerContainer = styled('div', ({ $theme }) => ({
  padding: 32,
  height: 'calc(100% - 64px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}));

const ContentArea = styled('div', {
  height: 0,
  flexGrow: 1,
  overflowY: 'auto'
});

const ThemesGridContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 32,
  flexWrap: 'wrap'
});

const GridItem = styled('div', ({ $theme }) => {
  const width = window.innerHeight > window.innerWidth ? (window.innerWidth - (32 * 2)) : (window.innerWidth - (32 * 4)) / 3;
  return {
    background: $theme.background3,
    width,
    height: width * (9 / 16),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  };
});

const BackgroundTile = React.memo(function BackgroundTile (props: React.PropsWithChildren<{
  background: ThemeBackgroundImageType | ThemeBackgroundVideoType
}>): JSX.Element {
  return (
    <div style={{
      position: 'relative',
      height: '100%',
      width: '100%'
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%'
      }}>
        {props.background.type === 'image'
          ? <img src={props.background.src} style={{
            objectFit: 'cover',
            height: '100%',
            width: '100%'
          }}/>
          : <video controls={false} autoPlay loop muted style={{
            objectFit: 'cover',
            height: '100%',
            width: '100%'
          }}>
            {props.background.srcs.map(src => (
              <source key={src.src} src={src.src} type={src.mimeType}/>
            ))}
          </video>}
      </div>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        zIndex: 1
      }}>
        {props.children}
      </div>
    </div>
  );
});

export const Home = (): JSX.Element => {
  const authState = seamlessAuth.useAuthState();
  const isEmployee = authState?.type === 'hiyllo-employee';
  const [showCreateTheme, setShowCreateTheme] = React.useState(false);

  const listThemesQuery = seamlessClient.useSeamlessQuery<ListThemesBP.Plug>(ListThemesBP, null);

  const onClickTheme = React.useCallback((theme: ThemeType) => {
    const redirectUrl = new URLSearchParams(window.location.search).get('rdr');
    window.location.href = `${redirectUrl ?? 'https://solo.hiyllo.io'}?theme=${theme.uuid}`;
  }, []);

  return (
    <OuterContainer>
      <InnerContainer>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 16,
          marginBottom: 32
        }}>
          <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png" style={{ height: 40 }}/>
          <Typography.Header style={{ fontSize: 36 }}>
            Hiyllo Themes
          </Typography.Header>
        </div>
        <ContentArea>
          {showCreateTheme
            ? <CreateTheme listThemesQuery={listThemesQuery} onDone={() => setShowCreateTheme(false)}/>
            : <ThemesGridContainer>
              {isEmployee
                ? <GridItem onClick={() => setShowCreateTheme(true)}>
                  <FontAwesomeIcon icon={faPlus}/>
                </GridItem>
                : null}
              {listThemesQuery.data?.themes.map(theme => (
                <GridItem key={theme.uuid} style={{ color: theme.colors.foreground }} onClick={() => {
                  onClickTheme(theme);
                }}>
                  <BackgroundTile background={theme.background}>
                    <div style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: 16,
                      paddingRight: 16
                    }}>
                      <div style={{
                        background: theme.colors.foreground,
                        color: theme.colors.accent,
                        padding: 8,
                        borderRadius: 4
                      }}>
                        {theme.background.attribution.creator}
                      </div>
                      <Typography.Header style={{ fontSize: 36 }}>{theme.label}</Typography.Header>
                      <Typography.SubHeader style={{ fontSize: 16 }}>{theme.description}</Typography.SubHeader>
                    </div>
                  </BackgroundTile>
                </GridItem>
              ))}
            </ThemesGridContainer>
          }
        </ContentArea>
      </InnerContainer>
    </OuterContainer>
  );
};
