/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ThemeType } from "../../types/theme";

export type ParamsType = null;
export type ResponseType = {
  themes: ThemeType[];
};
export const Endpoint = 'themes/list-themes';
export const Method = 'GET';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};
export const isPublic = true;export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}